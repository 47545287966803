import {  Track, VideoTrack } from 'livekit-client';
import React, {  useEffect, useState } from 'react';
import { ControlsView } from '../ControlsView';
import { ParticipantView } from '../ParticipantView';
import { StageProps } from '../StageProps';
import { defaultSortParticipants } from '../StageUtils';
import styles from './styles.module.css';

export const MobileStage = ({
  roomState,
  participantRenderer,
  controlRenderer,
  onLeave,
  sortParticipants,
}: StageProps) => {
  const { isConnecting, error, participants, room } = roomState;
  
  const [showOverlay, setShowOverlay] = useState(false);
  const sortFn = sortParticipants ?? defaultSortParticipants;
  const [sortedParticipants, setSortedParticipants] = useState(sortFn(participants));
  const itemsPerPage = 2; // 每页显示的数据条数
  const total = sortedParticipants.length;
  const allPage = Math.ceil(total / itemsPerPage);
  const [nowPage, setNowPage] = useState(1);
  const [showNext, setShowNext] = useState(false);
  const [showPre, setShowPre] = useState(false);

  const [nowParticipants, setNowParticipants] = useState(
    sortedParticipants.slice(0, itemsPerPage)
  );
  useEffect(() => {
    setSortedParticipants(sortFn(participants));
    if (participants.length > 0){
      setNowPage(1)
      setNowParticipants(participants.slice(0, itemsPerPage));
      // 大于2的时候显示下一页
      if (participants.length >2){
        setShowNext(true)
      }
      setShowPre(false)
    }
  }, [participants, sortFn]);

  if (error) {
    return <div>error {error.message}</div>;
  }

  if (isConnecting) {
    return <div>connecting</div>;
  }
  if (!room) {
    return <div>room closed</div>;
  }

  if (sortedParticipants.length === 0) {
    return <div>no one is in the room</div>;
  }

  const ParticipantRenderer = participantRenderer ?? ParticipantView;
  const ControlRenderer = controlRenderer ?? ControlsView;

  // find first participant with screen shared
  let screenTrack: VideoTrack | undefined;
  sortedParticipants.forEach((p) => {
    if (screenTrack) {
      return;
    }
    const track = p.getTrack(Track.Source.ScreenShare);
    if (track?.isSubscribed && track.videoTrack) {
      screenTrack = track.videoTrack;
    }
  });

  const nextPage = () => {
    // 先计算下一页的页码
    if (nowPage === allPage) {
      return;
    }
    const nextPageNumber = nowPage + 1;
    setShowPre(true)
    getPageData(nextPageNumber);
    
  };
  const prePage = () => {
    if (nowPage === 1) {
      return;
    };
    const nextPageNumber = nowPage - 1;
    setShowNext(true)
    getPageData(nextPageNumber);
  };
  const getPageData = (nextPageNumber:number) => {
    // 计算下一页的数据索引范围
    const startIndex = (nextPageNumber - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, total); // 确保 endIndex 不超过数组长度
    // 更新当前页和当前页数据
    setNowPage(nextPageNumber);
    setNowParticipants(sortedParticipants.slice(startIndex, endIndex));
    if (nextPageNumber === allPage) {
      setShowNext(false)
    }
    if(nextPageNumber === 1){
      setShowPre(false)
    }
  };
  return (
    // global container
    <div className={styles.container}>
      {/* <div className={styles.stage}>{mainView}</div> */}
      <div className={styles.participantsArea}>
        {nowParticipants.map((participant) => {
          return (
            <ParticipantRenderer
              key={participant.identity}
              participant={participant}
              className={styles.participant}
              aspectWidth={4}
              aspectHeight={3}
              showOverlay={showOverlay}
              onMouseEnter={() => setShowOverlay(true)}
              onMouseLeave={() => setShowOverlay(false)}
            />
          );
        })}
      </div>
      <div className={styles.btns}>
        <div style={{opacity:showPre ? 1:0.5}} className={styles.buttonStyle} onClick={prePage}>
          上一页
        </div>
        <div style={{opacity:showNext ? '1':'0.5'}} className={styles.buttonStyle} onClick={nextPage}>
          下一页
        </div>

      </div>
      <div className={styles.controlsArea}>
        <ControlRenderer room={room} enableScreenShare={false} onLeave={onLeave} />
      </div>
    </div>
  );
};
